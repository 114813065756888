import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { jsx, Grid } from "theme-ui";
import { MdWeb, MdShoppingCart, MdSearch, MdMoreHoriz, MdDesignServices, MdSpeakerNotes, MdShare, MdWebStories } from "react-icons/md";
import { IoAnalyticsSharp } from "react-icons/io5";
import ServiceCard from "../components/serviceCard";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Services`}</h2>
    <Grid columns={[1, 2, 4]} mdxType="Grid">
  <ServiceCard icon={<MdWeb mdxType="MdWeb" />} title="Site vitrine" mdxType="ServiceCard">
    Nous concevons et développons votre site vitrine pour présenter votre
    entreprise de façon professionnelle et efficace.
  </ServiceCard>
  <ServiceCard icon={<MdShoppingCart mdxType="MdShoppingCart" />} title="Site marchand" mdxType="ServiceCard">
    Nous réalisation votre site et sa boutique en ligne, et offrons à votre
    clientèle des achats et paiements sécurisés.
  </ServiceCard>
  <ServiceCard icon={<MdSearch mdxType="MdSearch" />} title="Référencement" mdxType="ServiceCard">
    Nous analysons et améliorons le référencement de votre site afin qu'il
    touche un plus large public.
  </ServiceCard>
  <ServiceCard icon={<MdWebStories mdxType="MdWebStories" />} title="Hébergement" mdxType="ServiceCard">
    Nous pouvons gérer l'hébergement de votre site pour que vous vous
    concentriez sur l'essentiel : votre entreprise.
  </ServiceCard>
  <ServiceCard icon={<MdDesignServices mdxType="MdDesignServices" />} title="Infographie" mdxType="ServiceCard">
    Nous réalisons pour vous la modernisation ou la création de votre identité
    de marque, ainsi que de supports de communications.
  </ServiceCard>
  <ServiceCard icon={<IoAnalyticsSharp mdxType="IoAnalyticsSharp" />} title="Publicité & Analytique" mdxType="ServiceCard">
    Nous lançons et gérons vos campagnes de publicité selon vos besoins, sur
    diverses plateformes, tout en vous fournissant des rapports analytiques de
    votre site.
  </ServiceCard>
  <ServiceCard icon={<MdShare mdxType="MdShare" />} title="Community Management" mdxType="ServiceCard">
    Nous créons et maintenons l'engagement de votre public à travers la gestion
    de vos réseaux sociaux.
  </ServiceCard>
  <ServiceCard icon={<MdSpeakerNotes mdxType="MdSpeakerNotes" />} title="Conseil" mdxType="ServiceCard">
    Nous mettons notre expertise au service de votre entreprise pour répondre à
    toutes vos questions, ou vous aiguiller dans tous vos projets.
  </ServiceCard>
  <ServiceCard icon={<MdMoreHoriz mdxType="MdMoreHoriz" />} title="Et plus !" mdxType="ServiceCard">
    Nous sommes constamment à la recherche de solutions pour nos clients.
    Parlez-nous de vos besoins et nous mettrons tout en oeuvre pour vous
    proposer des services sur mesure.
  </ServiceCard>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      