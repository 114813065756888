import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Transformez votre vision en `}<em>{`réalité.`}</em></h1>
    <p>{`Profitez de nos solutions web pour élargir votre public et assurer la satisfaction de votre clientèle.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      